<div class="container">
    <div class="row no-gutters align-items-center h-100">
        <div class="col-xs-12 col-sm-offset-2 col-sm-8">

            <h3 class="section-head">MAFIA All Gender MIR Party Registration</h3>
            <p class="text">
                <strong>Events at the Chicago Rose are limited to registered guests only. Registration will close 24-48 hours before the event or when we have reached maximum capacity.</strong>
                <br>
                <br>
                <strong><u><a class="btn mafia-btn-otl mb-3" href="https://docs.google.com/forms/d/e/1FAIpQLSecnNl4oOqQE3QgITEN8gZyTrw85aQR4oCU16JCyHCdb6iu9A/viewform" target="_blank">MAFIA All Gender MIR Party Registration</a></u></strong>
                <br>
                You will need to provide your legal name, birth date, phone number, and zip code. IDs will be checked at the door. MAFIA reserves the right to refuse entry to anyone for any reason.
            </p>
            <p class="text">
                Clothes check bags and limited shower facilities will be available during the party. We will provide snacks and non-alcoholic beverages. Smoking/vaping is only allowed in the alley behind the property.
                <br>
                <br>
                Illegal drug use is strictly forbidden at all times. If you are asked to leave the event for any reason, you will not be allowed to re-enter and your registration fee will not be refunded.
            </p>
            <p class="text">
                <br>
            </p>            

<!--            

            <h3 class="section-head">MAFIA Steamworks Party Registration</h3>
            <p class="text">
                Events at Steamworks are always open to the public. We transform the gym with slings and fisting benches! Remember to bring your personal lube.
                <br>
                <br>
                <strong><u><a class="btn mafia-btn-otl mb-3" href="https://docs.google.com/forms/d/e/1FAIpQLSfJmf13317tl8MKtCfxa6Al9S_boXyexXlcS6BXJR2lsGNHOQ/viewform" target="_blank">Steamworks Party Registration</a></u></strong>
                <br>
                You will need to provide your legal name, birth date, phone number, and zip code to make a reservation and will need to bring your valid ID to Steamworks. Your room or locker will be ready when you arrive. Unclaimed reservations are released at midnight.
            </p>
            <p class="text">
                All bags brought into the facility are checked at the front desk, and all regular Steamworks house rules and policies are in effect during our events.
                <br>
                <br>
                <strong><u><a class="btn mafia-btn-otl mb-3" href="https://www.steamworksbaths.com/chicago-rules" target="_blank">Steamworks Rules And Policies</a></u></strong>
                <br>
                No refunds will be given after the Tuesday before the party.
            </p>
            <p class="text">
                <br>
            </p> 

            <h3 class="section-head">MAFIA Market Days Chicago Rose Party Registration</h3>
            <p class="text">
                <strong>Events at the Chicago Rose are limited to registered guests only. Registration will close 24-48 hours before the event or when we have reached maximum capacity.</strong>
                <br>
                <br>
                <strong><u><a class="btn mafia-btn-otl mb-3" href="https://docs.google.com/forms/d/e/1FAIpQLScBu5JdMy-Rd3nCR3nSFO7eCAewVCmNQ_1eKiGuUYo1PpzjcA/viewform" target="_blank">MAFIA Chicago Rose Party Registration</a></u></strong>
                <br>
                You will need to provide your legal name, birth date, phone number, and zip code. IDs will be checked at the door. MAFIA reserves the right to refuse entry to anyone for any reason.
            </p>
            <p class="text">
                Clothes check bags and limited shower facilities will be available during the party. We will provide snacks and non-alcoholic beverages. Smoking/vaping is only allowed in the alley behind the property.
                <br>
                <br>
                Illegal drug use is strictly forbidden at all times. If you are asked to leave the event for any reason, you will not be allowed to re-enter and your registration fee will not be refunded.
            </p>
            <p class="text">
                <br>
            </p>
            
            <h3 class="section-head">MAFIA Black Hole Steamworks Invasion Party Registration</h3>
            <p class="text">
                Events at Steamworks are always open to the public. We transform the gym with slings and fisting benches! Remember to bring your personal lube.
                <br>
                <br>
                <strong><u><a class="btn mafia-btn-otl mb-3" href="https://docs.google.com/forms/d/e/1FAIpQLSdrDRi7VsJZf5uQLvSLzQi8q47ojijBcw92Q9bwH_6__2laMA/viewform" target="_blank">Steamworks Party Registration</a></u></strong>
                <br>
                You will need to provide your legal name, birth date, phone number, and zip code to make a reservation and will need to bring your valid ID to Steamworks. Your room or locker will be ready when you arrive. Unclaimed reservations are released at midnight.
            </p>
            <p class="text">
                All bags brought into the facility are checked at the front desk, and all regular Steamworks house rules and policies are in effect during our events.
                <br>
                <br>
                <strong><u><a class="btn mafia-btn-otl mb-3" href="https://www.steamworksbaths.com/chicago-rules" target="_blank">Steamworks Rules And Policies</a></u></strong>
                <br>
                No refunds will be given after the Tuesday before the party.
            </p>
            <p class="text">
                <br>
            </p> 

            <h3 class="section-head">MAFIA Black Hole Chicago Rose Dungeon Party Registration</h3>
            <p class="text">
                <strong>Events at the Chicago Rose are limited to registered guests only. Registration will close 24-48 hours before the event or when we have reached maximum capacity.</strong>
                <br>
                <br>
                <strong><u><a class="btn mafia-btn-otl mb-3" href="https://docs.google.com/forms/d/e/1FAIpQLSf7zN8n5J7J7B-1YambwtMMpqtmBN7Z18yRP9up9g2vctf8Nw/viewform" target="_blank">MAFIA Chicago Rose Party Registration</a></u></strong>
                <br>
                You will need to provide your legal name, birth date, phone number, and zip code. IDs will be checked at the door. MAFIA reserves the right to refuse entry to anyone for any reason.
            </p>
            <p class="text">
                Clothes check bags and limited shower facilities will be available during the party. We will provide snacks and non-alcoholic beverages. Smoking/vaping is only allowed in the alley behind the property.
                <br>
                <br>
                Illegal drug use is strictly forbidden at all times. If you are asked to leave the event for any reason, you will not be allowed to re-enter and your registration fee will not be refunded.
            </p>
            <p class="text">
                <br>
            </p>            

            
            
            <h3 class="section-head">MAFIA Annual General Meeting Registration</h3>
            <p class="text">
                Join us as we elect Officers & Board Members, celebrate the past year, and discuss the future of MAFIA
                <br>
                <br>
                <strong><u><a class="btn mafia-btn-otl mb-3" href="https://docs.google.com/forms/d/1Vgvzd3voDJwxTcwHTfbJ4-qRBu9pPoNzaPKOsybIFI8/prefill
                    " target="_blank">AGM Registration</a></u></strong>
                <br>
                
            </p>            

            <h3 class="section-head">MAFIA Chicago Rose Party Registration</h3>
            <p class="text">
                <strong>Events at the Chicago Rose are limited to registered guests only. Registration will close 24-48 hours before the event or when we have reached maximum capacity.</strong>
                <br>
                <br>
                <strong><u><a class="btn mafia-btn-otl mb-3" href="https://docs.google.com/forms/d/e/1FAIpQLSfAF8MmTqDpLCgKR39IVApe52FrSQuPXOs12DTwLWWj6GK89A/viewform" target="_blank">MAFIA Chicago Rose Party Registration</a></u></strong>
                <br>
                You will need to provide your legal name, birth date, phone number, and zip code. IDs will be checked at the door. MAFIA reserves the right to refuse entry to anyone for any reason.
            </p>
            <p class="text">
                Clothes check bags and limited shower facilities will be available during the party. We will provide snacks and non-alcoholic beverages. Smoking/vaping is only allowed in the alley behind the property.
                <br>
                <br>
                Illegal drug use is strictly forbidden at all times. If you are asked to leave the event for any reason, you will not be allowed to re-enter and your registration fee will not be refunded.
            </p>
            <p class="text">
                <br>
            </p>

            <h3 class="section-head">MAFIA Sunday Funday Play Event Registration</h3>
            <p class="text">
                <strong>This private event in The Hole at Jackhammer is limited to registered guests only. Registration will close 24-48 hours before the event or when we have reached maximum capacity.</strong>
                <br>
                <br>
                <strong><u><a class="btn mafia-btn-otl mb-3" href="https://docs.google.com/forms/d/e/1FAIpQLSfVs6_yjsO-9ZJ9vvQ4KLArWeJBNMaslX3BaYZwqEdUohqviQ/viewform" target="_blank">MAFIA Sunday Funday Play Event Registration</a></u></strong>
                <br>
                You will need to provide your legal name, birth date, phone number, and zip code. IDs will be checked at the door. MAFIA and Jackhammer reerve the right to refuse entry to anyone for any reason.
            </p>
            <p class="text">
                Clothes check bags and limited shower facilities will be available during the party. Alcoholic and non-alcoholic beverages are available for purchase. Tip your bartender! Smoking/vaping is only allowed outside the building.
                <br>
                <br>
                Illegal drug use is strictly forbidden at all times. If you are asked to leave the event for any reason, you will not be allowed to re-enter and your registration fee will not be refunded.
            </p>
            <p class="text">
                <br>
            </p>   
            
            -->
            
        </div>
    </div>
</div>
